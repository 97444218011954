import React, { useEffect, useState } from 'react';
import { FormControl, Input, useBreakpointValue } from '@chakra-ui/react';
import { SearchInputProps } from '@/components/searchValidation';
import { useBorderStyle } from '@/components/inputfields/inputUtilities';
import CustomLabel from '@/components/Forms/CustomLabel';

interface LocalFormData {
  query: string;
  date: Date | null;
  time: string;
  duration: number | null;
}

const SearchInput = ({
  setLocalFormData,
  height,
  value: initialValue = '',
}: SearchInputProps) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const width = useBreakpointValue({ base: 'full', md: '250px' });
  const borderStyles = useBorderStyle(width, 2, 'all');

  const [value, setValue] = useState<string | null>(initialValue);

  useEffect(() => {
    setValue(initialValue);
    setLocalFormData((prev: LocalFormData) => ({
      ...prev,
      query: initialValue || '',
    }));
  }, [initialValue, setLocalFormData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    setLocalFormData((prev: LocalFormData) => ({
      ...prev,
      query: newValue || '',
    }));
  };

  return (
    <FormControl {...borderStyles}>
      <CustomLabel labelText="Where" mb={0} />
      <Input
        ref={ref}
        value={value || undefined}
        height={height}
        fontSize="md"
        placeholder="e.g studio name.."
        variant="flushed"
        border="0"
        borderBottom="2px"
        borderColor="transparent"
        onChange={handleChange}
        _hover={{
          borderColor: 'honey.500',
        }}
        _focus={{
          borderColor: 'honey.500',
          boxShadow: 'none',
        }}
      />
    </FormControl>
  );
};

export default React.memo(SearchInput);
