import React from 'react';
import { Box, Flex, chakra } from '@chakra-ui/react';
import backgroundImage from '@/images/banner-3.jpg';
import SearchFilter from '@/components/Layouts/Filter/SearchFilter';

const HomeBanner = () => (
  <Flex
    w="full"
    h={{ base: 'auto', md: '450px' }}
    bgImage={`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`}
    bgPos="center"
    bgSize="cover"
    alignItems="center"
    justifyContent="center"
    direction="column"
    px={4}
    boxShadow="md"
    position="relative"
  >
    <Box
      textAlign="start"
      maxWidth={{ base: 'full', lg: '900px' }}
      w="full"
      py={{ base: 8, md: 12 }}
    >
      {/* Headers */}
      <Box p={4} ml={{ base: 4, md: 0 }} mb={0}>
        <chakra.h1
          fontSize="4xl"
          fontWeight="bold"
          color="white"
          textShadow="2px 2px 20px rgba(0, 0, 0, 0.8)"
        >
          Elevate your craft with{' '}
          <chakra.span color="honey.500">MuseHive</chakra.span>
        </chakra.h1>
        <chakra.h3
          fontSize="2xl"
          color="white"
          textShadow="2px 2px 20px rgba(0, 0, 0, 0.8)"
          // fontWeight="bold"
        >
          Discover and reserve the perfect studio tailored to your creative
          needs.
        </chakra.h3>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        position={{ base: 'relative', md: 'absolute' }} // Ensure relative positioning on mobile
        bottom={{ base: 'auto', md: '-60px' }} // Keep absolute positioning for desktop
        zIndex={2}
        maxW="900px"
        w="full"
        mt={{ base: 4, md: 0 }}
      >
        <SearchFilter />
      </Box>
    </Box>
  </Flex>
);

export default HomeBanner;
