import { useInfiniteQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import ms from 'ms';
import APIClient, {
  FetchResponse,
  PaginationMeta,
} from '@/services/api-client';
import { useStudioQueryStore } from '@/services/store';
import { getBrowserTimeZone } from '@/components/Utilities/FormatDateTime';
import { OperatingHoursInAPIResponse } from '@/components/Utilities/StudiosHandler';
import { flattenAttributes } from '@/components/Utilities/helper';

export interface SlateNode {
  type: string;
  children: { text: string }[];
}
export type Description = { text: string } | SlateNode[];

export interface Location {
  address: string;
  postal: string;
  latitude?: number;
  longitude?: number;
  country: string;
  timeZone: string;
}

export interface Studio {
  createdAt: string | number | Date;
  id?: number;
  studioName: string;
  description: Description;
  location: Location;
  roomName?: string;
  price?: number;
  studioType?: string[];
  images?: string[];
  stripeAccountId?: string | null;
  userId?: number;
  slug?: string;
  operatingHours: OperatingHoursInAPIResponse[];
  alwaysOpen?: boolean;
  studioTypeLabel?: string;
  minimumBookingDuration?: number;
}

const apiClient = new APIClient<Studio>('/v1/studios');

// Fetches all studios to display on the home page
const useStudios = () => {
  const studioQuery = useStudioQueryStore((state) => state.studioQuery);

  const {
    data,
    ...rest // other properties returned by useInfiniteQuery
  } = useInfiniteQuery<FetchResponse<Studio>, Error>({
    queryKey: ['studios', studioQuery],
    queryFn: ({ pageParam = 1 }) =>
      apiClient.getAll({
        params: {
          ...studioQuery,
          page: pageParam,
          perPage: 10,
          time_zone: getBrowserTimeZone(),
        },
      }),
    staleTime: ms('1d'),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const meta = lastPage.meta as PaginationMeta;
      return meta.nextPage;
    },
  });

  // Here, you can extract meta.total_entries from the last page
  const totalEntries =
    data?.pages?.[data?.pages?.length - 1]?.meta?.totalEntries ?? 0;

  const flattenedData =
    data?.pages?.map((page: any) => ({
      ...page,
      data: flattenAttributes(page?.data),
    })) ?? [];

  return { totalEntries, data: { ...data, pages: flattenedData }, ...rest };
};

export default useStudios;
