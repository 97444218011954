import React, { useState } from 'react';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { Flex, FormControl } from '@chakra-ui/react';
import {
  useManageReservationStore,
  UserDetails,
  useStudioQueryStore,
} from '@/services/store';
import FormModal from '@/components/Modal/FormModal';
import useOwnedStudios, { UserStudios } from '@/hooks/useOwnedStudios';
import useCreateReservation from '@/hooks/useCreateReservation';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import { LocalFormData } from '@/components/searchValidation';
import {
  combineDateAndTime,
  getBrowserTimeZone,
} from '@/components/Utilities/FormatDateTime';
import CustomSelect from '@/components/Forms/CustomSelect';
import { transformToStudioOptions } from '@/components/Utilities/BookingImport';
import CustomFormErrorMessage from '@/components/Forms/CustomFormErrorMessage';
import CustomTextInput from '@/components/Forms/CustomTextInput';
import CustomEmailInput from '@/components/Forms/CustomEmailInput';
import CustomNumberInput from '@/components/Forms/CustomNumberInput';
import DateInput from '@/components/inputfields/DateInput';
import StartTimeInput from '@/components/inputfields/StartTimeInput';
import DurationInput from '@/components/inputfields/DurationInput';
import StudioBookingTimeDetails from '@/components/DashBoard/ManageReservations/StudioBookingTimeDetails';

interface ManageReservationModalProps {
  user?: UserDetails | null;
  refetch: () => void;
}

const ManageReservationAddModal: React.FC<ManageReservationModalProps> = ({
  user,
  refetch,
}) => {
  const { isAddModalVisible, updateAddModalVisibility } =
    useManageReservationStore();
  const methods = useForm({
    mode: 'onChange',
  }); // Initialize react-hook-form
  const {
    control,
    formState: { errors },
  } = methods;
  const { studioQuery } = useStudioQueryStore((state) => state);
  const { data: studio, isLoading } = useOwnedStudios(user?.id, 'reservations');
  const { mutate, isPending } = useCreateReservation(user?.id);
  const showToast = useShowToastTop();
  const minDuration =
    studio.find((item: UserStudios) => item.id === methods.watch('studioId'))
      ?.minimumBookingDuration || 60;

  const initialValue = {
    date: studioQuery?.date || null,
    time: studioQuery?.time || '',
    duration: studioQuery?.duration || 0,
    query: '',
  };
  // Form state initialization
  const [formValues, setFormValues] = useState<LocalFormData>(initialValue);
  const dateString = formValues.date
    ? format(new Date(formValues.date), 'yyyy-MM-dd')
    : '';
  const formattedDateTime = combineDateAndTime(dateString, formValues.time);

  const handleSubmit = (data: any) => {
    const guestDetails = {
      name: data?.guestDetails?.name || null,
      email: data?.guestDetails?.email || null,
      contactNumber: data?.guestDetails?.contactNumber || null,
    };
    const totalPrice = data?.totalPrice || null;

    const payload = {
      ...data,
      dateTime: formattedDateTime,
      duration: formValues.duration,
      guestDetails,
      totalPrice,
      timeZone: getBrowserTimeZone(),
    };

    mutate(payload, {
      onSuccess: () => {
        updateAddModalVisibility();
        refetch();
        showToast({
          message: 'Reservation Created Successfully',
          status: 'success',
        });
        setFormValues(initialValue);
      },
      onError: (error: any) => {
        showToast({
          message: error?.response?.data?.error || 'An error occurred',
          status: 'error',
        });
      },
    });
  };

  return (
    <FormModal
      visible={isAddModalVisible}
      title="Add Reservation"
      onClose={updateAddModalVisibility}
      onSubmit={handleSubmit}
      methods={methods}
      isSubmitting={isPending}
    >
      <Flex direction="column" gap={3}>
        <Controller
          name="studioId"
          control={control}
          rules={{ required: 'Please select a studio' }}
          render={({ field: { onChange, value = '', name } }) => (
            <FormControl isInvalid={!!errors.studioId}>
              <CustomSelect
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                isLoading={isLoading}
                options={transformToStudioOptions(studio)}
                labelText="Select a Studio"
                customLabelStyle={{
                  fontSize: 'sm',
                  fontWeight: 'bold',
                  marginBottom: 0,
                }}
                isRequired
              />
              <CustomFormErrorMessage errors={errors} name={name} />
            </FormControl>
          )}
        />
        <CustomTextInput
          name="guestDetails.name"
          inputText="Name"
          control={control}
          customLabelStyle={{
            fontSize: 'sm',
            fontWeight: 'bold',
            marginBottom: 0,
          }}
        />
        <CustomEmailInput
          name="guestDetails.email"
          inputText="Contact Email"
          control={control}
          customLabelStyle={{
            fontSize: 'sm',
            fontWeight: 'bold',
            marginBottom: 0,
          }}
        />
        <CustomNumberInput
          name="guestDetails.contactNumber"
          control={control}
          inputText="Contact No."
          countryCode="+65"
          customLabelStyle={{
            fontSize: 'sm',
            fontWeight: 'bold',
            marginBottom: 0,
          }}
        />
        <CustomNumberInput
          name="totalPrice"
          control={control}
          inputText="Total Price"
          customLabelStyle={{
            fontSize: 'sm',
            fontWeight: 'bold',
            marginBottom: 0,
          }}
          type="number"
        />
        <Flex direction="column" gap={3} mt={2}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={{ base: 4, md: 2 }}
          >
            <DateInput
              setLocalFormData={setFormValues}
              defaultValue={formValues.date}
              enableCustomStyles={false}
            />
            <Flex gap={2}>
              <StartTimeInput
                setLocalFormData={setFormValues}
                localFormData={formValues}
                defaultValue={formValues.time}
                enableCustomStyles={false}
              />
              <DurationInput
                setLocalFormData={setFormValues}
                localFormData={formValues}
                defaultValue={formValues.duration}
                enableCustomStyles={false}
                minDuration={minDuration}
              />
            </Flex>
          </Flex>
          <StudioBookingTimeDetails
            startDateTime={formattedDateTime}
            duration={formValues?.duration || 0}
          />
        </Flex>
      </Flex>
    </FormModal>
  );
};

export default React.memo(ManageReservationAddModal);
