import { publicRoutes } from '@/components/Utilities/constants';

export const handleErrorResponse = async (error: any) => {
  const currentPath = window.location.pathname;

  const isPublicRoute = publicRoutes.some((route) => {
    const dynamicRoutePattern = new RegExp(
      `^${route.replace(/:\w+/g, '[^/]+')}$`,
    );
    return dynamicRoutePattern.test(currentPath);
  });

  if (!isPublicRoute && error?.response?.status === 401) {
    window.location.href = '/';
  }
  return Promise.reject(error);
};

export const isArray = (value: any): value is any[] => Array.isArray(value);

const isObject = (value: any): value is Record<string, unknown> =>
  value !== null && typeof value === 'object' && !Array.isArray(value);

export const flattenAttributes = (data: any[] | Record<any, any>) => {
  if (isArray(data)) {
    return data.map(({ attributes, ...rest }: any) => ({
      ...rest,
      ...attributes,
    }));
  }
  if (isObject(data)) {
    const { attributes, ...rest } = data as { attributes: Record<string, any> };
    return { ...attributes, ...rest };
  }
};
