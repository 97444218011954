import { format } from 'date-fns';
import { useCallback } from 'react';
import { toZonedTime } from 'date-fns-tz';
import APIClient from '@/services/api-client';
import { commonErrorMessage } from '@/components/Utilities/constants';
import {
  ToastTopProps,
  useShowToastTop,
} from '@/components/Feedback/ShowToastTop';
import { useStudioStore } from '@/services/store';
import { StudioDetails } from '@/components/CreateStudio/types';
import { defaultHoursDurations } from '@/components/CreateStudio/Constant';
import { getBrowserTimeZone } from '@/components/Utilities/FormatDateTime';

export type OperatingHoursInAPIResponse = {
  id: number;
  isClosed: boolean;
  openTime: string | null;
  closeTime: string | null;
  dayOfWeek: number;
};

export interface DeleteResponseType {
  message: string;
}

interface TimeSlot {
  openTime: string;
  closeTime: string;
  id: number | string;
}

interface OperatingHours {
  id?: number | string;
  isClosed: boolean;
  timeSlots: TimeSlot[];
}

export type DaysOfWeekProps = Record<
  number,
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
>;

const daysOfWeeks = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

const apiClient = new APIClient('/v1/studios');

export const useStudioDeleteAction = (refetch: () => void) => {
  const showToast = useShowToastTop();
  const {
    updateSubmitBtnLoading,
    setStudioDetails,
    updateDeleteModalVisibility,
  } = useStudioStore();
  return useCallback(
    async (id: string | number) => {
      try {
        updateSubmitBtnLoading(true);
        const response = await apiClient.delete(id);
        refetch();
        showToast({
          message: (response as DeleteResponseType)?.message,
          status: 'success',
        });
        updateDeleteModalVisibility();
        setStudioDetails(null);
      } catch (error: any) {
        showToast({
          message: error?.response?.data?.message || commonErrorMessage,
          status: 'error',
        });
      } finally {
        updateSubmitBtnLoading(false);
      }
    },
    [refetch],
  );
};

const formatTimeSlot = (time: string, timeZone: string) =>
  format(toZonedTime(time, timeZone), 'HH:mm');

export const formatOperatingHours = (
  data: OperatingHoursInAPIResponse[],
  timeZone: string,
  daysOfWeek: any | undefined = daysOfWeeks,
) => {
  const result: Record<string, OperatingHours> = {};
  const dayOfWeekMap: Record<number, string> = Object.fromEntries(
    Object.entries(daysOfWeek).map(([key, value]) => [value, key]),
  );

  data.forEach((day: any) => {
    const dayName = dayOfWeekMap[day.dayOfWeek];
    if (day.isClosed) {
      result[dayName] = {
        id: day.id,
        isClosed: true,
        timeSlots: [],
      };
    } else {
      const openTime = day?.openTime
        ? formatTimeSlot(day.openTime, timeZone)
        : defaultHoursDurations?.openTime;
      const closeTime = day?.closeTime
        ? formatTimeSlot(day.closeTime, timeZone)
        : defaultHoursDurations.closeTime;

      result[dayName] = {
        isClosed: false,
        timeSlots: [
          ...(result[dayName]?.timeSlots || []),
          { openTime, closeTime, id: day?.id },
        ],
      };
    }
  });

  return result;
};

const handleStudioDetailsDate = (details: any, daysOfWeek: any) => {
  const timeZone =
    details?.data?.attributes?.location?.timeZone || getBrowserTimeZone();

  const getFormattedOperatingHours = () =>
    formatOperatingHours(
      details.data.attributes.operatingHours,
      timeZone,
      daysOfWeek,
    );

  const formattedHours = details?.data?.attributes?.alwaysOpen
    ? {}
    : getFormattedOperatingHours();

  return {
    ...details.data.attributes,
    ...details.data.attributes.location,
    operatingHours: formattedHours,
    imagesUpload: details.data.attributes.images,
    description: JSON.parse(details.data.attributes.description),
  };
};

export const getStudioDetails = async (
  id: string,
  setStudioDetails: (studio: StudioDetails | null) => void,
  daysOfWeek: Record<number, string> | undefined,
  showToast: (props: ToastTopProps) => void,
  navigate: any,
) => {
  try {
    const response: any = await apiClient.get(`${id}/studio_data`);
    setStudioDetails(handleStudioDetailsDate(response, daysOfWeek));
  } catch (error: any) {
    showToast({
      message: error?.response?.data?.error || commonErrorMessage,
      status: 'error',
    });
    navigate('/dashboard/manage_studios');
  }
};
