import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { isPast } from 'date-fns';
import { UserReservation } from '@/hooks/useUserReservations';
import { convertUTCToLocalTime } from '@/components/Utilities/FormatDateTime';
import { formatDuration } from '@/components/Utilities/FormatDuration';
import StatusIndicator from '@/components/Utilities/StatusIndicator';
import { useCancelBookingStore } from '@/services/store';
import CancelBookingDialog from '@/components/DashBoard/ManageReservations/CancelBookingDialog';

interface Props {
  reservation: UserReservation;
  refetch: () => void;
}

const ReservationCard = ({ reservation, refetch }: Props) => {
  const {
    updateCancelBookingModalVisibility,
    updateCancelReservationId,
    cancelReservationId,
  } = useCancelBookingStore();

  const durationString = formatDuration(reservation?.duration || 0);
  const { studio } = reservation;
  const { startDate, endDate, startTime, endTime, bookingExpired } =
    convertUTCToLocalTime(reservation.startDatetime, reservation.duration);
  const studioImage =
    studio.images && studio.images.length > 0 ? studio.images[0] : '';
  const isCancelBookingBtnVisible =
    reservation.status === 'confirmed' &&
    !isPast(new Date(reservation.startDatetime));

  const handleCancelBooking = () => {
    updateCancelBookingModalVisibility(true);
    updateCancelReservationId(reservation?.id);
  };

  return (
    <Tooltip
      label="Booking has expired."
      isDisabled={!bookingExpired}
      hasArrow
      placement="top"
    >
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        borderRadius={6}
        opacity={reservation.status === 'cancelled' || bookingExpired ? 0.5 : 1} // Apply lower opacity for cancelled reservations
        width={{ base: 'full', sm: 'xxl' }} // Extended width for larger screens
        height="auto"
        border="1px"
        borderColor="powder.600"
        boxShadow="md"
      >
        <Flex direction={{ base: 'column', sm: 'row' }} w="full" h="full">
          <Image
            objectFit="cover"
            overflow="hidden"
            maxW={{ base: '100%', sm: '250px' }}
            maxH={{ base: '180px', sm: 'full' }}
            src={studioImage}
            alt="Image of studio"
            order={{ base: -1, sm: 1 }} // Image first on mobile, then content; reverse on larger screens
          />
          <Stack flex={1} gap={2}>
            <CardBody pb={2}>
              <Heading size="md" fontWeight="light">
                {studio.studioName} in {studio.roomName}
              </Heading>
              <Box fontSize="sm" mb={2}>
                <Box as="span" fontWeight="bold" fontSize="sm">
                  {startDate ? `${startDate}, ` : ''} {startTime}
                  {startDate !== endDate
                    ? `to ${endDate ? `${endDate}, ` : ''}${endTime}`
                    : `- ${endTime}`}
                </Box>
                <Text fontSize="sm">{durationString}</Text>
              </Box>
              <Text fontSize="sm">
                {studio.location.address}, {studio.location.postal}
              </Text>
              <Text fontSize="sm">Booking ID: {reservation.bookingNumber}</Text>
              {reservation.specialRequests && (
                <Text fontSize="sm">
                  Special Requests: {reservation.specialRequests}
                </Text>
              )}
            </CardBody>
            <CardFooter pt={2} w="full">
              <Flex justifyContent="space-between" alignItems="end" w="full">
                <VStack gap={0} align="start">
                  <Text fontSize="sm" fontWeight="bold">
                    Total paid: S$ {reservation?.totalPrice.toFixed(2)}
                  </Text>
                  <StatusIndicator status={reservation.status} />
                </VStack>
                <ButtonGroup size="sm" spacing={2}>
                  {isCancelBookingBtnVisible && (
                    <Tooltip
                      label="Cancelling this booking 48 hours prior may incur a penalty. Please refer to our cancellation policy."
                      placement="top"
                      isDisabled={reservation?.bookingType === 'offline'}
                    >
                      <Button
                        onClick={handleCancelBooking}
                        colorScheme="red"
                        variant="outline"
                      >
                        Cancel booking
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Flex>
            </CardFooter>
          </Stack>
        </Flex>
        {cancelReservationId === reservation.id && (
          <CancelBookingDialog
            userId={reservation?.userId}
            reservationId={reservation?.id}
            bookingType={reservation?.bookingType}
            refetch={refetch}
            userRole="user"
          />
        )}
      </Card>
    </Tooltip>
  );
};

export default ReservationCard;
