import { Badge, Box, Flex, Image, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import React, { useRef } from 'react';
import { Studio } from '@/hooks/useStudios';
import TextWithTooltip from '@/components/TextWithTooltip';

interface DiscoverCardProps {
  studio: Studio;
}

const DiscoverCard = ({ studio }: DiscoverCardProps) => {
  const location = useLocation();
  const imagesArray = Array.isArray(studio.images) ? studio.images : [];
  const bookingDuration = (studio.minimumBookingDuration || 60) / 60;
  const image =
    imagesArray[0] ||
    'https://demo-basic.adminkit.io/img/photos/unsplash-1.jpg'; // Default image if none exists
  const studioNameRef = useRef<HTMLDivElement>(null);
  const roomNameRef = useRef<HTMLDivElement>(null);
  const addressRef = useRef<HTMLDivElement>(null);

  return (
    <Link
      to={`/studios/${studio?.slug ? studio.slug : studio.id}${location.search}`}
      target="_blank"
    >
      <Box
        maxW="sm"
        borderRadius="lg"
        overflow="hidden"
        border={0}
        bg="white"
        boxShadow="md"
        mx={2}
        mb={2}
        position="relative"
      >
        {/* Badge for minimum booking duration */}
        <Badge
          colorScheme="orange"
          position="absolute"
          top="10px"
          right="10px"
          zIndex="1"
          borderRadius={4}
          px={3}
          py={1}
        >
          min. {bookingDuration} hr
        </Badge>
        <Image
          src={image}
          alt={`Image of ${studio.studioName}`}
          objectFit="cover"
          overflow="hidden"
          height="200px"
          width="100%"
        />
        <Flex
          p={2}
          height="130px"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" justifyContent="start">
            <TextWithTooltip
              text={studio.studioName}
              fontSize="xl"
              ref={studioNameRef}
            />
            <TextWithTooltip
              text={studio.roomName || ''}
              fontSize="md"
              ref={roomNameRef}
              fontWeight="medium"
            />
            <TextWithTooltip
              text={`${studio.location.address}, ${studio.location.postal}`}
              fontSize="sm"
              ref={addressRef}
              fontWeight="medium"
              color="gray.500"
            />
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="baseline"
          >
            <Text display="inline" fontSize="xl" fontWeight="bold">
              S${studio.price}
            </Text>
            <Text display="inline" fontSize="sm">
              {' '}
              /hour
            </Text>
          </Box>
        </Flex>
      </Box>
    </Link>
  );
};

export default DiscoverCard;
