import React from 'react';
import {
  useDisclosure,
  Flex,
  Box,
  Image,
  Button,
  VisuallyHidden,
  HStack,
  IconButton,
  VStack,
  CloseButton,
  Skeleton,
  Container,
} from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import logo from '@/images/MuseHive_Horizontal-logo-Main_RGB.png';
import { useAuthStore, useStudioQueryStore } from '@/services/store';

const NavbarPreAuth = () => {
  // const bg = useColorModeValue('powder.500', 'gray.800');
  const { isLoading } = useAuthStore();
  const mobileNav = useDisclosure();
  const navigate = useNavigate();
  const { resetStudioQuery } = useStudioQueryStore();
  const resetQuery = () => {
    resetStudioQuery();
  };

  const handleSignInClick = () => {
    navigate('/auth/sign_in');
  };

  const handleSignUpClick = () => {
    navigate('/auth/sign_up');
  };

  return (
    <Container
      as="header"
      w="full"
      shadow="md"
      bg="white"
      position="sticky"
      maxW="100vw"
      zIndex={100}
      top={0}
    >
      <Container justifyContent="center" py={4} maxWidth="1300px">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="full"
          mx="auto"
        >
          <Flex>
            <Box title="MuseHive" display="flex" alignItems="center">
              <Link to="/" onClick={resetQuery}>
                <HStack spacing={8} alignItems="center">
                  <Image src={logo} alt="Logo" height={14} width="auto" />
                </HStack>
              </Link>
              <VisuallyHidden>MuseHive</VisuallyHidden>
            </Box>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={2}
              mr={1}
              color="garageGrey.500"
              display={{ base: 'none', md: 'inline-flex' }}
            >
              <Skeleton isLoaded={!isLoading}>
                <Button variant="ghost" color="garageGrey.500">
                  About us
                </Button>
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Button variant="ghost" color="garageGrey.500">
                  Support
                </Button>
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Button
                  onClick={handleSignInClick}
                  variant="outline"
                  colorScheme="garageGrey"
                >
                  Sign in
                </Button>
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Button
                  colorScheme="garageGrey"
                  variant="solid"
                  onClick={handleSignUpClick}
                >
                  Register
                </Button>
              </Skeleton>
            </HStack>
            <Box display={{ base: 'inline-flex', md: 'none' }}>
              <IconButton
                display={{ base: 'flex', md: 'none' }}
                aria-label="Open menu"
                fontSize="20px"
                color="gray.800"
                _dark={{ color: 'inherit' }}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? 'flex' : 'none'}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                spacing={3}
                rounded="sm"
                shadow="sm"
                zIndex={99999}
              >
                <CloseButton
                  aria-label="Close menu"
                  onClick={mobileNav.onClose}
                />

                <Button w="full" variant="ghost">
                  About us
                </Button>
                <Button w="full" variant="ghost">
                  Support
                </Button>
                <Button w="full" variant="ghost" onClick={handleSignUpClick}>
                  Register
                </Button>
                <Button w="full" variant="ghost" onClick={handleSignInClick}>
                  Sign in
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </Container>
    </Container>
  );
};

export default React.memo(NavbarPreAuth);
