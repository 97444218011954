import { create } from 'zustand';
import { toZonedTime } from 'date-fns-tz';
import { initialTime, localDateTime } from '@/services/initialTime';
import {
  calculateEndDateTime,
  durationToString,
  formatDate,
  formatTime,
  parseDateTime,
} from '@/services/dateTimeUtility';
import { StripeDetailsProps } from '@/components/Utilities/StripeConnection';
import { getBrowserTimeZone } from '@/components/Utilities/FormatDateTime';
import { StudioDetails } from '@/components/CreateStudio/types';
import { flattenAttributes } from '@/components/Utilities/helper';
import { UserReservation } from '@/hooks/useUserReservations';

export interface StudioQuery {
  query?: string | undefined | null;
  date: Date | null;
  time: string | undefined | null;
  duration: number | null;
  endTime?: string | undefined | null;
  endDate?: string | undefined | null;
}

// initialTime() returns the current time rounded to the nearest 30-minute interval
const getURLParams = () => {
  const urlParam = new URLSearchParams(window.location.search);
  return {
    query: urlParam.get('query') || '',
    date: urlParam.get('date') || null,
    time: urlParam.get('time') || initialTime(),
    duration: parseInt(urlParam.get('duration') || '120', 10),
  };
};

const urlParams = getURLParams();
const zonedDate = urlParams.date
  ? toZonedTime(new Date(urlParams.date), getBrowserTimeZone())
  : localDateTime;

const initialStudioQuery: StudioQuery = {
  query: urlParams.query,
  date: urlParams.date ? zonedDate : localDateTime,
  time: urlParams.time,
  duration: urlParams.duration,
};

interface StudioForm {
  images: (string | File)[];
}

interface StudioFormStore {
  // Fill in all the setters
  formData: StudioForm;
  setFormData: (formData: StudioForm) => void;
  setImages: (images: (string | File)[]) => void;
  isDeleteModalVisible: boolean;
  updateDeleteModalVisibility: () => void;
  studioDetails: StudioDetails | null;
  setStudioDetails: (studioDetails: StudioDetails | null) => void;
  submitBtnLoading: boolean;
  updateSubmitBtnLoading: (loading: boolean) => void;
}

interface StudioQueryStore {
  studioQuery: StudioQuery;
  // setStudioId: (studioId: number) => void;
  setQuery: (query: string) => void;
  setDate: (date: Date) => void;
  setTime: (time: string) => void;
  setDuration: (duration: number) => void;
  resetStudioQuery: () => void;
  calculateEnd: () => void;
  getFormattedDateTime: () => {
    formattedDate: string;
    formattedTime: string;
    formattedEndDate: string;
    formattedEndTime: string;
    durationInString: string;
  } | null;
}

// export interface Equipment {
//   category: string[];
//   quantity: number;
//   name: string;
//   brand: string;
// }

// interface EquipmentStore {
//   equipmentList: Equipment[];
//   setEquipmentList: (equipmentList) => void;
//   updateEquipmentRow: (index: number, updatedRow: Equipment) => void;
// }

export const useStudioQueryStore = create<StudioQueryStore>((set, get) => ({
  studioQuery: initialStudioQuery,
  setQuery: (query) =>
    set((state) => ({ studioQuery: { ...state.studioQuery, query } })),
  setDate: (date) =>
    set((state) => ({ studioQuery: { ...state.studioQuery, date } })),
  setTime: (time) =>
    set((state) => ({ studioQuery: { ...state.studioQuery, time } })),
  setDuration: (duration) => {
    if (duration >= 0) {
      set((state) => ({ studioQuery: { ...state.studioQuery, duration } }));
    }
  },
  // Calculating End Time Date based on date time duration
  calculateEnd: () => {
    const { date, time, duration } = get().studioQuery;
    if (date && time && duration != null) {
      const startDateTime = parseDateTime(date, time);
      const endDateTime = calculateEndDateTime(startDateTime, duration);

      set((state) => ({
        studioQuery: {
          ...state.studioQuery,
          endTime: formatTime(endDateTime),
          endDate: formatDate(endDateTime),
        },
      }));
    }
  },
  resetStudioQuery: () => {
    set({ studioQuery: initialStudioQuery });
  },
  getFormattedDateTime: () => {
    const { date, time, duration } = get().studioQuery;
    if (date && time && duration != null) {
      const startDateTime = parseDateTime(date, time);
      const endDateTime = calculateEndDateTime(startDateTime, duration);
      return {
        formattedDate: formatDate(startDateTime),
        formattedTime: formatTime(startDateTime),
        formattedEndDate: formatDate(endDateTime),
        formattedEndTime: formatTime(endDateTime),
        durationInString: durationToString(duration),
      };
    }
    return null;
  },
}));

const initialFormState: StudioForm = {
  images: [],
};

export const useStudioStore = create<StudioFormStore>((set) => ({
  formData: initialFormState,
  setFormData: (formData) => set({ formData }),
  setImages: (images) =>
    set((state) => ({ formData: { ...state.formData, images } })),
  isDeleteModalVisible: false,
  updateDeleteModalVisibility: () =>
    set((state) => ({ isDeleteModalVisible: !state.isDeleteModalVisible })),
  studioDetails: null,
  setStudioDetails: (studioDetails) =>
    set({
      studioDetails,
      formData: { images: studioDetails?.imagesUpload || [] },
    }),
  submitBtnLoading: false,
  updateSubmitBtnLoading: (loading: boolean) =>
    set({ submitBtnLoading: loading }),
}));

interface User {
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  dateOfBirth?: string;
  gender?: string;
  image?: string;
}

interface UserProfileStore {
  userData: User;
  setUserData: (userData: Partial<User>) => void;
  setDateOfBirth: (dateOfBirth: string) => void;
}

export const useUserProfileStore = create<UserProfileStore>((set) => ({
  userData: {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    dateOfBirth: '',
    gender: '',
    image: '',
  },
  setUserData: (userData) =>
    set((state) => ({ userData: { ...state.userData, ...userData } })),
  setDateOfBirth: (dateOfBirth) =>
    set((state) => ({ userData: { ...state.userData, dateOfBirth } })),
}));

// -- Start of User Authentication -- //

export interface UserDetails {
  allowPasswordChange: boolean;
  approvedByAdmin: boolean;
  dateOfBirth: string | null;
  email: string;
  admin: boolean;
  authenticationToken: string;
  firstName: string;
  gender: string;
  id: number | undefined;
  image: string | null;
  lastName: string;
  mobileNumber: string | null;
  provider: string;
  studioOwner?: boolean;
  uid: string;
  stripeAccountId?: string;
  stripeAccountStatus?: string | null;
  isAdmin?: boolean;
}

export interface AuthState {
  isAuthenticated: boolean;
  user: UserDetails | null;
  setAuth: (user: UserDetails) => void;
  clearAuth: () => void;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  studioOwner?: boolean;
}

export const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  setAuth: (user) => set({ isAuthenticated: true, user }),
  clearAuth: () =>
    set({ isAuthenticated: false, isLoading: false, user: null }),
  setLoading: (isLoading) => set({ isLoading }),
}));

// -- End of User Authentication -- //

// -- Reservation Id Store -- //
interface ReservationStore {
  reservation: UserReservation | null;
  setReservation: (reservation: UserReservation) => void;
}

export const useReservationStore = create<ReservationStore>((set) => ({
  reservation: null,
  setReservation: (reservation) => set({ reservation }),
}));

// -- End of Reservation Id Store -- //

// -- Stripe Connection Store -- //
interface StripeConnectionStore {
  isSignUpBtnLoading: boolean;
  setIsSignUpBtnLoading: () => void;
  isPageLoading: boolean;
  setIsPageLoading: (loading: boolean) => void;
  stripeDetails: StripeDetailsProps | null;
  updateStripeDetails: (details: any) => void;
  isConfirmBtnLoading: boolean;
  setIsConfirmBtnLoading: () => void;
  isModalVisible: boolean;
  updateModalVisibility: (visible: boolean) => void;
}

export const useStripeConnectionStore = create<StripeConnectionStore>(
  (set) => ({
    isSignUpBtnLoading: false,
    setIsSignUpBtnLoading: () =>
      set((state) => ({ isSignUpBtnLoading: !state.isSignUpBtnLoading })),
    isPageLoading: true,
    setIsPageLoading: (loading: boolean) =>
      set(() => ({ isPageLoading: loading })),
    stripeDetails: null,
    updateStripeDetails: (details: any) => set({ stripeDetails: details }),
    isConfirmBtnLoading: false,
    setIsConfirmBtnLoading: () =>
      set((state) => ({ isConfirmBtnLoading: !state.isConfirmBtnLoading })),
    isModalVisible: false,
    updateModalVisibility: (visible: boolean) =>
      set(() => ({ isModalVisible: visible })),
  }),
);
// -- End of Stripe Connection Store -- //

interface ImportResult {
  id: number;
  fileName: string;
  startedAt: string; // ISO date string
  completedAt: string; // ISO date string
  totalRecords: number;
  savedRecords: number;
  failedRecords: number;
  fileUrl: string;
  errorFileUrl: string;
  ownerId: number;
  status: string;
}

interface MetaData {
  currentPage: number;
  nextPage?: number | null;
  prevPage?: number | null;
  totalPages: number;
  totalEntries: number;
}

interface ImportResponse {
  data: ImportResult[];
  meta: MetaData;
}

// -- Booking Import Store -- //
interface BookingImportStore {
  isAddModalVisible: boolean;
  updateAddModalVisibility: () => void;
  tableLoading: boolean;
  updateTableLoading: () => void;
  reservationsList: ImportResponse;
  updateReservationList: (data: any) => void;
  submitBtnLoading: boolean;
  setSubmitBtnLoading: () => void;
}

export const initialValueForReservationList = {
  data: [],
  meta: {
    currentPage: 0,
    totalPages: 0,
    totalEntries: 0,
  },
};

export const useBookingImportStore = create<BookingImportStore>((set) => ({
  isAddModalVisible: false,
  updateAddModalVisibility: () =>
    set((state) => ({ isAddModalVisible: !state.isAddModalVisible })),
  tableLoading: false,
  updateTableLoading: () =>
    set((state) => ({ tableLoading: !state.tableLoading })),
  reservationsList: initialValueForReservationList,
  updateReservationList: (details) =>
    set(() => ({
      reservationsList: {
        ...details,
        data: flattenAttributes(details?.data || []),
      },
    })),
  submitBtnLoading: false,
  setSubmitBtnLoading: () =>
    set((state) => ({ submitBtnLoading: !state.submitBtnLoading })),
}));
// -- End of Booking Import Store -- //

// -- Manage Reservation Store -- //
interface ManageReservationStore {
  isAddModalVisible: boolean;
  updateAddModalVisibility: () => void;
}

export const useManageReservationStore = create<ManageReservationStore>(
  (set) => ({
    isAddModalVisible: false,
    updateAddModalVisibility: () =>
      set((state) => ({ isAddModalVisible: !state.isAddModalVisible })),
  }),
);
// -- End of Manage Reservation Store -- //

// -- Cancel Booking Store -- //
interface CancelBookingStore {
  isCancelBookingModalVisible: boolean;
  updateCancelBookingModalVisibility: (visible: boolean) => void;
  cancelReservationId: number | string;
  updateCancelReservationId: (id: number | string) => void;
}

export const useCancelBookingStore = create<CancelBookingStore>((set) => ({
  isCancelBookingModalVisible: false,
  updateCancelBookingModalVisibility: (visible: boolean) =>
    set(() => ({ isCancelBookingModalVisible: visible })),
  cancelReservationId: '',
  updateCancelReservationId: (id: number | string) =>
    set(() => ({ cancelReservationId: id })),
}));
// -- End of Cancel Booking Store -- //
