import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  GridItemProps,
  // Input,
  InputProps,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CustomInputProps extends InputProps {
  inputText: string;
  colSpan?: GridItemProps['colSpan'];
  name: string;
  control: any;
  customLabelStyle?: Record<string, any>;
  studioNames: any;
}

const ComboBox = ({
  inputText,
  control,
  name,
  colSpan,
  customLabelStyle = {},
  studioNames = [],
  ...props
}: CustomInputProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormControl
        mt={2}
        as={GridItem}
        colSpan={colSpan}
        isInvalid={!!fieldState.error}
        {...props}
      >
        <CustomLabel labelText={inputText} {...customLabelStyle} />
        <AutoComplete
          onSelectOption={(value) => {
            field.onChange(value.item.value);
          }}
          emptyState={null}
          openOnFocus
        >
          <AutoCompleteInput
            {...field}
            type="text"
            autoComplete="off"
            placeholder={inputText}
            rounded="md"
            onChange={field?.onChange}
            height={10}
            p={2}
            pl={4}
            pr={4}
            _placeholder={{
              fontSize: 'sm',
              color: 'gray.400',
            }}
          />
          <AutoCompleteList>
            {studioNames.map((studioName: string, id: number) => (
              <AutoCompleteItem key={id} value={studioName}>
                {studioName}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        </AutoComplete>
        <FormErrorMessage>
          {fieldState.error ? fieldState.error.message : ''}
        </FormErrorMessage>
      </FormControl>
    )}
  />
);

export default ComboBox;
