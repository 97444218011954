import { format } from 'date-fns';
import type { TableColumnsType } from 'antd';
import { capitalize } from 'lodash';
import {
  Box,
  Flex,
  Tag,
  Button as ChakraButton,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import { MdCancel } from 'react-icons/md';
import { formatDuration } from '@/components/Utilities/FormatDuration';
import { convertUTCToLocalTime } from '@/components/Utilities/FormatDateTime';
import { parseLocalDateTimeToUTC } from '@/services/dateTimeUtility';
import { handleFileDownload } from '@/components/Utilities/FileDownload';

export interface FlattenedBooking {
  id: number;
  studioId: number;
  bookingNumber: number;
  bookingType: string;
  studioName: string;
  reservationId: number;
  startDate: string;
  startTime: string;
  endDate: string;
  totalPrice: string;
  netEarnings: string;
  endTime: string;
  duration: number;
  userEmail: string;
  status: string;
  roomName: string;
  image: string;
  paymentStatus: string;
  refundedAmount: string;
  userId: number;
  startDatetime: string;
  specialRequests?: string;
  invoiceReceiptUrl?: string;
}

export const statusColors: { [key: string]: string } = {
  pending: 'yellow',
  confirmed: 'green',
  cancelled: 'red',
  payment_pending: 'yellow',
  payment_succeeded: 'green',
  refund_initiated: 'orange',
  refunded: 'green',
  default: 'gray',
};

export const handleAction = (
  record: FlattenedBooking,
  setSelectedReservation: (data: any) => void,
  onOpen: (visible: boolean) => void,
) => {
  setSelectedReservation(record);
  if (onOpen) {
    onOpen(true);
  }
};

const renderTag = (
  value: string,
  colors: { [key: string]: string },
  variant: string = 'subtle',
) =>
  value ? (
    <Tag variant={variant} colorScheme={colors[value] || colors.default}>
      {capitalize(value.replaceAll('_', ' '))}
    </Tag>
  ) : (
    '-'
  );

const renderDateTime = (date: string) =>
  `${format(new Date(date), 'dd MMM yy')}, ${format(new Date(date), 'hh:mm a')}`;

const renderEarnings = (value: string | number) =>
  `S$${Number(value).toFixed(2)}`;

const renderGuestDetails = (details: any) => {
  const { name, email, contactNumber } = details || {};
  const availableDetails = [name, email, contactNumber].filter(Boolean);

  return availableDetails.length ? (
    <Flex direction="column">
      {availableDetails.map((detail, index) => (
        <Box key={index}>
          {detail}
          {index < availableDetails.length - 1 && ','}
        </Box>
      ))}
    </Flex>
  ) : (
    '-'
  );
};

const renderActionButtons = (
  record: FlattenedBooking,
  setSelectedReservation: (data: any) => void,
  updateCancelBookingModal: (visible: boolean) => void,
) => {
  const currentDateTime = new Date();
  const recordEndDateTime = new Date(`${record.endDate}T${record.endTime}`);
  const isPastEndDateTime = recordEndDateTime < currentDateTime;
  const { invoiceReceiptUrl } = record;
  const fileName =
    invoiceReceiptUrl?.split('/')[invoiceReceiptUrl?.split('/').length - 1];

  // Initialize an array to hold button elements
  const buttons = [];

  if (invoiceReceiptUrl) {
    buttons.push(
      <Tooltip key="download-invoice" label="Download Invoice" hasArrow>
        <ChakraButton
          fontWeight="light"
          onClick={(event) => {
            event.stopPropagation();
            handleFileDownload(invoiceReceiptUrl, fileName, true);
          }}
          size={{ base: 'xs', md: 'sm' }}
        >
          <DownloadIcon />
        </ChakraButton>
      </Tooltip>,
    );
  }

  if (
    record?.bookingType !== 'online' &&
    record?.status !== 'cancelled' &&
    !isPastEndDateTime
  ) {
    buttons.push(
      <Tooltip key="cancel-booking" label="Cancel Booking" hasArrow>
        <ChakraButton
          ml={2}
          variant="outline"
          colorScheme="red"
          fontWeight="light"
          size={{ base: 'xs', md: 'sm' }}
          onClick={(event) => {
            event.stopPropagation();
            handleAction(
              record,
              setSelectedReservation,
              updateCancelBookingModal,
            );
          }}
        >
          <MdCancel />
        </ChakraButton>
      </Tooltip>,
    );
  }

  return buttons.length > 0 ? buttons : '-';
};

export const getTableColumns = (
  setSelectedReservation: (data: any) => void,
  updateCancelBookingModal: (visible: boolean) => void,
): TableColumnsType<FlattenedBooking> => [
  {
    title: 'S/N',
    key: 'serialNumber',
    render: (_, __, index) => `${index + 1}.`,
  },
  { title: 'Studio Name', dataIndex: 'studioName' },
  { title: 'Booking Type', dataIndex: 'bookingType', render: capitalize },
  { title: 'Room Name', dataIndex: 'roomName' },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status) => renderTag(status, statusColors, 'solid'),
  },
  {
    title: 'Payment Status',
    dataIndex: 'paymentStatus',
    render: (status) => renderTag(status, statusColors),
  },
  {
    title: 'Start Date',
    render: (_, { startDatetime }) => renderDateTime(startDatetime),
    sorter: (a, b) =>
      new Date(a.startDatetime).getTime() - new Date(b.startDatetime).getTime(),
  },
  {
    title: 'End Date',
    render: (_, { startDatetime, duration }) => {
      const { endDate, endTime } = convertUTCToLocalTime(
        startDatetime,
        duration,
      );
      return renderDateTime(`${parseLocalDateTimeToUTC(endDate, endTime)}`);
    },
  },
  { title: 'Duration', dataIndex: 'duration', render: formatDuration },
  { title: 'Booking No.', dataIndex: 'bookingNumber' },
  {
    title: 'Guest Details',
    dataIndex: 'guestDetails',
    render: renderGuestDetails,
  },
  {
    title: 'Earnings',
    dataIndex: 'netEarnings',
    render: renderEarnings,
    sorter: (a, b) => parseFloat(a.netEarnings) - parseFloat(b.netEarnings),
  },
  {
    title: <Box textAlign="center">Total Price</Box>,
    dataIndex: 'totalPrice',
    render: (value) => {
      if (Number.isInteger(value)) {
        return `S$${value}`;
      }
      return `S$${value.toFixed(2)}`;
    },
    sorter: (a, b) => parseFloat(a.totalPrice) - parseFloat(b.totalPrice),
  },
  {
    title: 'Refunded Amount',
    dataIndex: 'refundedAmount',
    render: renderEarnings,
    sorter: (a, b) =>
      parseFloat(a.refundedAmount) - parseFloat(b.refundedAmount),
  },
  {
    title: 'Special Requests',
    dataIndex: 'specialRequests',
    align: 'center',
    render: (value: string) => value || '-',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center',
    render: (_, record) =>
      renderActionButtons(
        record,
        setSelectedReservation,
        updateCancelBookingModal,
      ),
  },
];
