import React from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  VisuallyHidden,
  Skeleton,
  Container,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '@/images/MuseHive_Horizontal-logo-Main_RGB.png';
import { useAuthStore, useStudioQueryStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import UserMenu from '@/components/Layouts/UserMenu';
import useSignOut from '@/hooks/useSignOut';
import { generateMenuItems } from '@/components/Layouts/helper';

const NavbarPreAuth = () => {
  const { user, isLoading } = useAuthStore();
  const navigate = useNavigate();
  const showToast = useShowToastTop();
  const { resetStudioQuery } = useStudioQueryStore();
  const resetQuery = () => {
    resetStudioQuery();
  };
  const { mutate: signOut, isPending } = useSignOut();
  const menuItems = generateMenuItems(user, signOut, isPending);

  const handleClick = () => {
    if (
      user?.stripeAccountId &&
      user?.stripeAccountStatus?.toLowerCase() === 'active'
    ) {
      navigate('/studios/new');
    } else {
      showToast({
        message:
          // eslint-disable-next-line
          "To create a Studio, it's necessary to integrate a Stripe account.",
        status: 'error',
      });
      navigate('/dashboard/payment_settings');
    }
  };

  return (
    <Container
      as="header"
      borderBottom="1px"
      borderColor="powder.600"
      bg="white"
      w="full"
      position="sticky"
      maxW="100vw"
      zIndex={100}
      top={0}
    >
      <Container justifyContent="center" py={4} maxWidth="1300px">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="full"
          mx="auto"
        >
          <Flex>
            <Box title="MuseHive" display="flex" alignItems="center">
              <Link to="/" onClick={resetQuery}>
                <HStack spacing={8} alignItems="center">
                  {/* Skeleton for the logo */}
                  <Skeleton isLoaded={!isLoading}>
                    <Image src={logo} alt="Logo" height={14} width="auto" />
                  </Skeleton>
                </HStack>
              </Link>
              <VisuallyHidden>MuseHive</VisuallyHidden>
            </Box>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="garageGrey.500"
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <Skeleton isLoaded={!isLoading}>
                <Button
                  zIndex={9999}
                  colorScheme="garageGrey"
                  onClick={handleClick}
                >
                  Register your studio
                </Button>
              </Skeleton>
            </HStack>
            <Skeleton isLoaded={!isLoading}>
              <UserMenu user={user} menuItems={menuItems} isProfileMenu />
            </Skeleton>
            <Skeleton
              isLoaded={!isLoading}
              display={{ base: 'block', md: 'none' }}
            >
              <UserMenu user={user} menuItems={menuItems} />
            </Skeleton>
          </HStack>
        </Flex>
      </Container>
    </Container>
  );
};

export default NavbarPreAuth;
