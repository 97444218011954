import { DaysOfWeekProps } from '@/components/Utilities/StudiosHandler';
import { OperatingHours } from '@/components/CreateStudio/types';

interface OutputSchedule {
  id?: number;
  isClosed: boolean;
  openTime: string | null;
  closeTime: string | null;
  dayOfWeek: number;
}

const convertSchedule = (input: any, daysOfWeek: any): OutputSchedule[] => {
  const output: OutputSchedule[] = [];

  Object.keys(input || {}).forEach((day) => {
    const daySchedule = input[day];
    const dayOfWeek = daysOfWeek[day];

    if (daySchedule.isClosed || daySchedule.timeSlots.length === 0) {
      output.push({
        id: daySchedule?.id,
        isClosed: true,
        openTime: null,
        closeTime: null,
        dayOfWeek,
      });
    } else {
      daySchedule.timeSlots.forEach((slot: any) => {
        output.push({
          id: slot?.id,
          isClosed: false,
          openTime: slot.openTime,
          closeTime: slot.closeTime,
          dayOfWeek,
        });
      });
    }
  });

  return output;
};
export const createFormData = (
  data: any,
  images: (string | File)[],
  daysOfWeek: DaysOfWeekProps | undefined,
  deletedOperatingHours: OperatingHours,
  removedImage: (string | File)[] = [],
) => {
  const formDataToSend = new FormData();

  formDataToSend.append('studio[terms_agreed]', data.termsAgreed);
  formDataToSend.append('studio[studio_name]', data.studioName);
  formDataToSend.append('studio[room_name]', data.roomName);
  formDataToSend.append('studio[studio_type]', data.studioType);
  formDataToSend.append('studio[contact_number]', data.contactNumber);
  formDataToSend.append('studio[email_address]', data.emailAddress);
  formDataToSend.append('studio[price]', String(data.price));
  formDataToSend.append('studio[description]', data.description);
  formDataToSend.append('studio[location][address]', data.address);
  formDataToSend.append('studio[location][postal]', data.postal);
  formDataToSend.append('studio[location][country]', data.country);
  formDataToSend.append('studio[location][time_zone]', data.timeZone);
  formDataToSend.append(
    'studio[minimum_booking_duration]',
    String(data.minimumBookingDuration),
  );
  formDataToSend.append('studio[always_open]', String(data.alwaysOpen));

  const appendOperatingHours = (
    hours: OutputSchedule[],
    formData: FormData,
    isDeleted: boolean,
  ) => {
    hours.forEach((item: OutputSchedule) => {
      formData.append(
        'studio[operating_hours_attributes][][day_of_week]',
        String(item?.dayOfWeek),
      );
      formData.append(
        'studio[operating_hours_attributes][][is_closed]',
        String(item?.isClosed),
      );
      if (isDeleted) {
        formData.append(
          'studio[operating_hours_attributes][][_destroy]',
          String(isDeleted),
        );
      }
      if (!item?.isClosed) {
        formData.append(
          'studio[operating_hours_attributes][][open_time]',
          item?.openTime || '',
        );
        formData.append(
          'studio[operating_hours_attributes][][close_time]',
          item?.closeTime || '',
        );
      }
      if (item?.id) {
        formData.append(
          'studio[operating_hours_attributes][][id]',
          String(item?.id),
        );
      }
    });
  };

  if (!data.alwaysOpen) {
    appendOperatingHours(
      convertSchedule(data.operatingHours, daysOfWeek),
      formDataToSend,
      false,
    );
    if (Object.keys(deletedOperatingHours || {}).length > 0) {
      appendOperatingHours(
        convertSchedule(deletedOperatingHours, daysOfWeek),
        formDataToSend,
        true,
      );
    }
  }

  images.forEach((image: File | string) => {
    if (typeof image === 'object') {
      formDataToSend.append('studio[images][]', image);
    }
  });

  if (removedImage?.length > 0) {
    removedImage.forEach((image: File | string) => {
      formDataToSend.append('studio[images_to_delete][]', image);
    });
  }

  return formDataToSend;
};
