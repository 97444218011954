import React, { useRef, useState } from 'react';
import {
  Box,
  FormControl,
  Input,
  InputGroup,
  useOutsideClick,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SetLocalFormData } from '@/components/searchValidation';
import {
  selectFieldStyles,
  useBorderStyle,
} from '@/components/inputfields/inputUtilities';
import CustomLabel from '@/components/Forms/CustomLabel';

interface DateInputProps {
  setLocalFormData: SetLocalFormData;
  onDateSelect?: (date: Date) => void;
  defaultValue?: Date | null;
  height?: string;
  enableCustomStyles?: boolean;
}

const DateInput = ({
  setLocalFormData,
  onDateSelect,
  defaultValue,
  height,
  enableCustomStyles = true,
}: DateInputProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    defaultValue || new Date(),
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const borderStyles = useBorderStyle('130px', 0, 'left');
  const fieldStyles = enableCustomStyles ? selectFieldStyles() : {};

  const wrapperRef = useRef(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      setLocalFormData((prev) => ({ ...prev, date }));
      onDateSelect?.(date);
    }
    setIsDropdownOpen(false);
  };

  const handleInputClick = () => setIsDropdownOpen((prev) => !prev);

  useOutsideClick({
    ref: wrapperRef,
    handler: () => setIsDropdownOpen(false),
  });

  return (
    <Box ref={wrapperRef} position="relative">
      <FormControl {...(enableCustomStyles ? borderStyles : {})}>
        <CustomLabel labelText="Date" mb={0} />
        <InputGroup onClick={handleInputClick}>
          <Input
            placeholder="Select date"
            height={height}
            backgroundColor="white"
            value={selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''}
            {...fieldStyles}
            readOnly
          />
        </InputGroup>
        {isDropdownOpen && (
          <Box
            position="absolute"
            top="100%"
            mt={2}
            borderRadius="md"
            zIndex={9999}
          >
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              minDate={new Date()}
              inline
            />
          </Box>
        )}
      </FormControl>
    </Box>
  );
};

export default React.memo(DateInput);
