import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  GridItemProps,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CustomInputProps extends InputProps {
  inputText: string;
  countryCode?: string;
  colSpan?: GridItemProps['colSpan'];
  value?: string;
  control: any;
  name: string;
  setValue?: (value: string) => void;
  customLabelStyle?: Record<string, any>;
}

const CustomNumberInput = ({
  inputText,
  countryCode,
  control,
  name,
  colSpan,
  customLabelStyle = {},
  ...props
}: CustomInputProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormControl
        as={GridItem}
        colSpan={colSpan}
        mt={2}
        isInvalid={!!fieldState.error}
        {...props}
      >
        <CustomLabel labelText={inputText} {...customLabelStyle} />
        <InputGroup borderColor="powder.500">
          {countryCode && (
            <InputLeftAddon bgColor="powder.500" color="black">
              {countryCode}
            </InputLeftAddon>
          )}
          <Input
            {...field}
            type="tel"
            placeholder={inputText}
            rounded="md"
            height={10}
            onBlur={field.onBlur}
            onChange={field.onChange}
            p={2}
            pl={4}
            pr={4}
            _placeholder={{
              fontSize: 'sm',
              color: 'gray.400',
            }}
            {...props}
          />
        </InputGroup>
        <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
      </FormControl>
    )}
  />
);

export default CustomNumberInput;
