import React from 'react';

interface Props {
  duration: number | null;
  time: string | null | undefined;
  date: Date | null;
  query: string | null | undefined;
}

export interface LocalFormData {
  query: string;
  date: Date | null;
  time: string;
  duration: number | null;
}

export type SetLocalFormData = (
  value: React.SetStateAction<LocalFormData>,
) => void;

export interface SearchInputProps {
  height: string | undefined;
  setLocalFormData: SetLocalFormData;
  value?: string | null;
}

const searchValidation = ({ duration, date, time, query }: Props): boolean => {
  // one of the 3 key fields are filled
  const anyFieldFilled = !!duration || !!time || !!date;
  // all 3 key fields are filled
  const allKeyFieldsFilled = !!duration && !!time && !!date;
  // all fields are filled
  const allFieldsFilled = !!duration && !!time && !!date && !!query;
  // only text fields are filled
  const onlyTextFieldFilled = !!query && !allKeyFieldsFilled;

  const noFieldsFilled = !anyFieldFilled && !query;

  return (
    allFieldsFilled ||
    allKeyFieldsFilled ||
    onlyTextFieldFilled ||
    noFieldsFilled
  );
};

export default searchValidation;
