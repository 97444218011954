export const defaultHoursDurations = { openTime: '08:00', closeTime: '17:00' };

export const defaultOperatingHours: any = {
  monday: {
    isClosed: false,
    timeSlots: [defaultHoursDurations],
  },
  tuesday: {
    isClosed: false,
    timeSlots: [defaultHoursDurations],
  },
  wednesday: {
    isClosed: false,
    timeSlots: [defaultHoursDurations],
  },
  thursday: {
    isClosed: false,
    timeSlots: [defaultHoursDurations],
  },
  friday: {
    isClosed: false,
    timeSlots: [defaultHoursDurations],
  },
  saturday: {
    isClosed: true,
    timeSlots: [],
  },
  sunday: {
    isClosed: true,
    timeSlots: [],
  },
};

const defaultDescription = [{ type: 'paragraph', children: [{ text: '' }] }];

export const defaultValues = {
  studioName: '',
  roomName: '',
  studioType: 'rehearsal_recording',
  contactNumber: '',
  emailAddress: '',
  address: '',
  postal: '',
  country: 'Singapore',
  price: 30,
  description: defaultDescription,
  imagesUpload: [],
  termsAgreed: false,
  operatingHours: defaultOperatingHours,
  alwaysOpen: false,
  minimumBookingDuration: 60,
};

export const title = {
  house_rules: 'House Rules',
  amenities: 'Amenities',
  cancellation: 'Cancellation Policies',
  type: 'Studio type(s)',
};
