import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Text,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker } from 'antd';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';
// import { ErrorResponse } from '@/hooks/useAuth';
import useUserData from '@/hooks/useUserData';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomNumberInput from '@/components/Forms/CustomNumberInput';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import CustomLabel from '@/components/Forms/CustomLabel';
import AlertDialogComponent from '../Utilities/AlertDialogComponent';
import { genderOptions } from '@/components/Utilities/constants';
import { BorderedBox } from '@/components/Utilities/CustomBorder';

const UserProfileEdit = () => {
  const MIN_AGE = 13;
  const MAX_AGE = 90;
  const userProfileSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    dateOfBirth: z
      .string({
        invalid_type_error: 'Date of birth is required',
      })
      .refine(
        (val) => {
          const date = new Date(val);
          return !Number.isNaN(date.getTime()) && date <= new Date();
        },
        {
          message: 'Date of birth cannot be in the future or invalid',
        },
      )
      .transform((val) => dayjs(val).format('YYYY-MM-DD')),
    mobileNumber: z
      .string()
      .min(1, 'Contact number is required.')
      .regex(/^\d{8}$/, 'Contact number must be exactly 8 digits.'),
    gender: z.string(),
    email: z.string().email(),
  });

  /* eslint-disable */
  const datePickerStyle = css`
    .ant-picker {
      border-radius: var(--chakra-radii-md);
      border: 1px solid var(--chakra-colors-gray-200);
      font-size: var(--chakra-fontSizes-md);
      height: var(--chakra-sizes-10);
      padding: var(--chakra-space-2);
      width: 49%;
      &:hover {
        border-color: var(--chakra-colors-gray-300);
      }
      &:focus {
        box-shadow: var(--chakra-shadows-outline);
        border-color: var(--chakra-colors-blue-500);
      }
    }`;
  /* eslint-enable */

  const { userData, setUserData } = useUserProfileStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const userObject = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    dateOfBirth: user?.dateOfBirth || '',
    mobileNumber: user?.mobileNumber || '',
    gender: user?.gender || 'Male',
    email: user?.email || '',
  };
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: userObject,
    resolver: zodResolver(userProfileSchema),
  });
  const { mutate, isPending } = useUserData();
  const showToast = useShowToastTop();
  useEffect(() => {
    if (user) {
      reset(userObject); // Reset form values to user data on load
    }
  }, [user, reset]);

  const handleChange = (date: any) => {
    setUserData({ ...userData, dateOfBirth: date });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = (data: any) => {
    const fullUserData = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: data.dateOfBirth,
        mobileNumber: data.mobileNumber,
        gender: data.gender,
        email: data.email,
      },
    };
    mutate(fullUserData, {
      onSuccess: (response: any) => {
        showToast({
          message: response.message,
          status: 'success',
        });
        onClose();
      },
      onError: (error) => {
        const message = (error as any)?.response?.data?.errors[0];
        showToast({
          message,
          status: 'error',
        });
        onClose();
      },
    });
  };

  const disabledDate = (current: Dayjs | null) => {
    const today = dayjs();
    const minDate = today.subtract(MAX_AGE, 'year');
    const maxDate = today.subtract(MIN_AGE, 'year');
    if (!current) return false;
    return current.isBefore(minDate) || current.isAfter(maxDate);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading fontSize={{ base: 'xl', md: '2xl' }}>Update Profile</Heading>
        <BorderedBox
          display="flex"
          flexDirection="column"
          gap={6}
          w="full"
          maxW={{ base: 'full', lg: '2xl' }}
          bg={useColorModeValue('white', 'gray.700')}
          rounded="xl"
          p={6}
          mt={15}
        >
          <HStack alignItems="center">
            <CustomInputField
              name="firstName"
              label="First name"
              type="text"
              control={control}
              defaultValue={user?.firstName}
              isRequired
            />
            <CustomInputField
              name="lastName"
              label="Last name"
              type="text"
              control={control}
              defaultValue={user?.lastName}
              isRequired
            />
          </HStack>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                mt={2}
                id="dateOfBirth"
                css={datePickerStyle}
                width="full"
                isInvalid={!!fieldState.error}
                isRequired
              >
                <CustomLabel labelText="Date of Birth" />
                <DatePicker
                  format="DD MMM YYYY"
                  onChange={(date) => {
                    const formattedDate = date
                      ? date.format('YYYY-MM-DD')
                      : null;
                    field.onChange(formattedDate);
                    handleChange(date);
                  }}
                  value={field.value ? dayjs(field.value) : null}
                  disabled={!!user?.dateOfBirth}
                  disabledDate={disabledDate} // Disable dates based on age restrictions
                  status={fieldState.error ? 'error' : ''}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <CustomNumberInput
            name="mobileNumber"
            inputText="Contact No."
            countryCode="+65"
            control={control}
            isRequired
          />
          <Controller
            name="gender"
            control={control}
            defaultValue={user?.gender}
            render={({ field, fieldState }) => (
              <FormControl id="gender" isRequired>
                <CustomLabel labelText="Gender" />
                <RadioGroup colorScheme="honey" {...field} value={field.value}>
                  <Stack {...field} direction="row">
                    {genderOptions.map((option) => (
                      <Radio key={option.value} value={option.value}>
                        {option.label}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
          <FormControl mt={2} id="email">
            <CustomLabel labelText="Email Address" />
            {/* Instead of an input field, show text */}
            <Text fontSize="md" color="gray.700">
              {user?.email || 'No email provided'}
            </Text>
          </FormControl>
          <Stack spacing={[4, 6]} direction={['column-reverse', 'row']}>
            <Button
              colorScheme="honey"
              w="full"
              onClick={onOpen}
              isLoading={isPending}
            >
              Save
            </Button>
          </Stack>
        </BorderedBox>
        <AlertDialogComponent
          isOpen={isOpen}
          isPending={isPending}
          onClose={onClose}
          onConfirm={handleSubmit(onSubmit)}
          title="Confirm Profile Update"
          body="Are you sure you want to save the changes to your profile?"
        />
      </form>
    </Box>
  );
};

export default UserProfileEdit;
