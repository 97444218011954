import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import Button, { ButtonVariantProps } from '@/components/Buttons';

interface FormModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  title: string;
  children: React.ReactNode;
  submitButtonText?: string;
  cancelButtonText?: string;
  isSubmitting?: boolean;
  methods: UseFormReturn<any, any, undefined>;
  modalSize?: string | Record<string, string>;
  hideCloseIcon?: boolean;
  submitBtnVariant?: ButtonVariantProps;
  cancelBtnVariant?: ButtonVariantProps;
}

const FormModal: React.FC<FormModalProps> = ({
  visible,
  onClose,
  onSubmit,
  title,
  children,
  submitButtonText = 'Submit',
  cancelButtonText = 'Cancel',
  isSubmitting = false,
  methods,
  modalSize = { base: 'xs', sm: 'md', md: 'lg' },
  hideCloseIcon = false,
  submitBtnVariant = 'primary',
  cancelBtnVariant = 'ghost',
}) => {
  useEffect(() => {
    if (!visible) {
      methods.reset();
    }
  }, [visible, methods]);

  return (
    <Modal isOpen={visible} onClose={onClose} isCentered size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom="1px solid" borderColor="gray.100">
          {title}
        </ModalHeader>
        {!hideCloseIcon && <ModalCloseButton />}
        <ModalBody p={0}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box p={5}>{children}</Box>
              <ModalFooter borderTop="1px solid" borderColor="gray.100">
                <Button
                  variant={cancelBtnVariant}
                  onClick={onClose}
                  mr={3}
                  disabled={isSubmitting}
                  fontWeight="bold"
                >
                  {cancelButtonText}
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  variant={submitBtnVariant}
                  fontWeight="bold"
                >
                  {submitButtonText}
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(FormModal);
