import React, { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { BiSort } from 'react-icons/bi';
import { TriangleDownIcon } from '@chakra-ui/icons';

interface SortMenuProps {
  setSortOrder: React.Dispatch<React.SetStateAction<string>>;
}

const SortMenu: React.FC<SortMenuProps> = ({ setSortOrder }) => {
  const bg = useColorModeValue('powder.500', 'powder.500');
  const [selectedOption, setSelectedOption] = useState('Top picks');

  const handleSelect = (option: string) => {
    setSortOrder(option);
    setSelectedOption(option);
  };

  const menuOptions = [
    { label: 'Price Low to High' },
    { label: 'Price High to Low' },
    { label: 'Sorting by Newest' },
    { label: 'Sorting by Oldest' },
  ];

  return (
    <Menu>
      <MenuButton
        as={Button}
        bg={bg}
        _expanded={{ bg }}
        _hover={{ backgroundColor: 'powder.600' }}
        _active={{ backgroundColor: 'powder.800' }}
        leftIcon={<Icon as={BiSort} boxSize={6} ml={2} />}
      >
        Sort by:{' '}
        <span style={{ fontWeight: 'normal' }}> {selectedOption} </span>
        {/* Optional: Arrow icon on the right */}
        <Icon as={TriangleDownIcon} boxSize={2} />
      </MenuButton>
      <MenuList border={0}>
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelect(option.label)}
            _focus={{ bg: 'powder.600' }}
            _hover={{ bg: 'powder.600' }}
            _active={{ bg: 'powder.800' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default SortMenu;
