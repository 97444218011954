import React, { ReactNode } from 'react';
import { BorderedBox } from '@/components/Utilities/CustomBorder';

interface Props {
  children: ReactNode;
}

const StudioCardContainer = ({ children }: Props) => (
  <BorderedBox
    transition="transform 0.2s"
    _hover={{
      transform: 'scale(1.05)', // Increase size by 10% when hovered
      cursor: 'pointer',
    }}
    borderRadius={10}
    overflow="hidden"
    boxShadow="md"
  >
    {children}
  </BorderedBox>
);

export default StudioCardContainer;
