import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { GoPencil } from 'react-icons/go';
import { Control, Controller } from 'react-hook-form';
import {
  Element,
  Leaf,
  toggleMark,
  Toolbar,
} from '@/components/Forms/SlateComponent';
import { countCharacters } from '@/components/Forms/Helper';
import { MAX_LENGTH } from '@/components/CreateStudio/StudioFormSchema';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export interface RichTextBlockProps {
  control: Control<any>;
  name: string;
}

export const RichTextBlock: React.FC<RichTextBlockProps> = ({
  control,
  name,
}) => {
  const [characterCount, setCharacterCount] = useState(0);
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const savedSelection = useRef(editor.selection);

  const onFocus = useCallback(() => {
    if (!editor.selection) {
      Transforms.select(
        editor,
        savedSelection.current ?? Editor.end(editor, []),
      );
    }
  }, [editor]);

  const onBlur = useCallback(() => {
    savedSelection.current = editor.selection;
  }, [editor]);

  const divRef = useRef<HTMLDivElement>(null);

  const focusEditor = useCallback(
    (e: React.MouseEvent) => {
      if (e.target === divRef.current) {
        ReactEditor.focus(editor);
        e.preventDefault();
      }
    },
    [editor],
  );

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    for (const [hotkey, mark] of Object.entries(HOTKEYS)) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault();
        toggleMark(editor, mark);
      }
    }
  };

  const renderPlaceholder = useCallback(
    (props: any) => (
      <span {...props.attributes} style={{ opacity: 0.3 }}>
        Describe your studio here...
      </span>
    ),
    [],
  );

  const label = (
    <>
      Provide detailed description of your studio. Things you can include:
      <ul>
        <li>Unique features</li>
        <li>Room size</li>
        <li>Restrictions</li>
        <li>House rules</li>
        <li>etc..</li>
      </ul>
    </>
  );

  return (
    <Box
      ref={divRef}
      onMouseDown={focusEditor}
      bg="white"
      border="1px"
      borderColor="powder.600"
      px={4}
      py={6}
      rounded={[null, 'md']}
      shadow="base"
    >
      <Heading fontSize="lg" fontWeight="medium">
        <Flex alignItems="flex-end" fontWeight={600}>
          <Icon as={GoPencil} w={5} h={5} mr={2} />
          <Tooltip
            label={label}
            padding={2}
            aria-label="A tooltip"
            placement="top"
            fontSize="md"
          >
            Description
          </Tooltip>
        </Flex>
      </Heading>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState }) => {
          const initialValue =
            typeof value === 'string' ? JSON.parse(value) : value;
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setCharacterCount(countCharacters(initialValue));
          }, [initialValue]);

          const validateContent = (newValue: any) => {
            if (countCharacters(newValue) > MAX_LENGTH) {
              control.setError(name, {
                type: 'manual',
                message: `Description must be at most ${MAX_LENGTH} characters long`,
              });
              return false;
            }

            control.setError(name, {});
            return true;
          };

          return (
            <FormControl isInvalid={!!fieldState.error}>
              <Slate
                key={JSON.stringify(initialValue)}
                editor={editor}
                initialValue={initialValue}
                onChange={(newValue) => {
                  validateContent(newValue);
                  onChange(JSON.stringify(newValue));
                  console.log({ testing: countCharacters(newValue) });
                  setCharacterCount(countCharacters(newValue));
                }}
              >
                <Toolbar />
                <Box padding="15px 5px">
                  <Editable
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    renderPlaceholder={renderPlaceholder}
                    spellCheck
                    style={{
                      padding: '10px',
                      minHeight: '200px',
                      resize: 'vertical',
                      overflow: 'auto',
                    }}
                  />
                </Box>
              </Slate>
              <FormHelperText>
                {characterCount} / {MAX_LENGTH} characters
              </FormHelperText>
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
