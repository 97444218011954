import React, { useState } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Icon,
  Text,
  Avatar,
  useBreakpointValue,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MdClose, MdOutlineMenu } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import { UserDetails } from '@/services/store';

interface UserMenuProps {
  menuItems: any[];
  user: UserDetails | null;
  isProfileMenu?: boolean;
}

const getUserName = (user: UserDetails | null): string =>
  user?.firstName && user?.lastName
    ? `${user.firstName} ${user.lastName}`
    : user?.email || 'MuseHive User';

const registerStudioMenu = {
  label: 'Register your Studio',
  key: 'register_studio',
  icon: FaPlus,
  to: '/studios/new',
};

const UserMenu: React.FC<UserMenuProps> = ({
  menuItems,
  user,
  isProfileMenu,
}) => {
  const showFullMenu = useBreakpointValue({ base: false, md: true });
  const filterKeys = [
    'booking_import',
    'admin_panel',
    'manage_reservations',
    'bookings',
    'my_studios',
    'payment_settings',
  ];
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let filteredMenuItems = menuItems;

  if (showFullMenu) {
    filteredMenuItems = menuItems;
  } else if (isProfileMenu) {
    filteredMenuItems = menuItems.filter(
      (item) => !filterKeys.includes(item.key),
    );
  } else {
    filteredMenuItems = menuItems.filter((item) =>
      filterKeys.includes(item.key),
    );
    filteredMenuItems.push(registerStudioMenu);
  }

  // Divide filtered menu items into two groups: Studio Management and Account Settings
  const studioManagementMenuItems = filteredMenuItems.filter((item) =>
    [
      'my_studios',
      'manage_reservations',
      'booking_import',
      'bookings',
    ].includes(item.key),
  );
  const accountSettingsMenuItems = filteredMenuItems.filter((item) =>
    [
      'edit_profile',
      'change_password',
      'payment_settings',
      'logout',
      'admin_panel',
    ].includes(item.key),
  );

  const menuItemStyle = {
    borderRadius: 5,
    _hover: { bg: 'powder.500' },
    _active: { bg: 'powder.700' },
  };

  const handleMenuButtonContent = () => {
    if (isProfileMenu) {
      return (
        <>
          <Avatar size="sm" name={getUserName(user)} bg="honey.500" />
          <Text display={{ base: 'none', md: 'block' }}>
            {getUserName(user)}
          </Text>
        </>
      );
    }
    return <Icon as={isOpen ? MdClose : MdOutlineMenu} boxSize={6} />;
  };

  return (
    <Menu
      autoSelect={false}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <MenuButton
        as={Button}
        border="1px"
        borderColor="powder.600"
        _hover={{ boxShadow: 'md' }}
        _active={{ bgColor: 'powder.500' }}
        bg="white"
        textColor="garageGrey.500"
        px={{ base: '5px', md: 4 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          {handleMenuButtonContent()}
        </Box>
      </MenuButton>
      <MenuList
        p={2}
        border="none"
        position="absolute"
        zIndex={100}
        boxShadow="xl"
        right={{
          base: '-40px',
          md: user?.firstName && user?.lastName ? '-100px' : '-140px',
          xl: '-145px',
        }}
      >
        {/* Group: Studio Management */}
        <MenuGroup title="Studio Management">
          {studioManagementMenuItems.map(
            ({ icon, label, to, as, key, ...props }) => (
              <MenuItem
                key={key}
                {...menuItemStyle}
                icon={<Icon as={icon} boxSize={4} />}
                as={as || (to && Link)}
                to={to}
                href={as ? to : undefined}
                {...props}
              >
                {label}
              </MenuItem>
            ),
          )}
        </MenuGroup>
        {user?.studioOwner && <MenuDivider />}
        {/* Group: Account Settings */}
        <MenuGroup title="Account Settings">
          {accountSettingsMenuItems.map(
            ({ icon, label, to, as, key, ...props }) => (
              <MenuItem
                key={key}
                {...menuItemStyle}
                icon={<Icon as={icon} boxSize={4} />}
                as={as || (to && Link)}
                to={to}
                href={as ? to : undefined}
                {...props}
              >
                {label}
              </MenuItem>
            ),
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
