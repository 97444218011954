import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';
import { Studio } from '@/hooks/useStudios';

const apiClient = new APIClient<Studio>('/v1/studios/random');

// Fetches all reservations for a studio
const useRandomStudios = () =>
  useQuery({
    queryKey: ['random-studios'],
    queryFn: () => apiClient.getAll({}),
    staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours
  });

export default useRandomStudios;
