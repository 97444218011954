import {
  VStack,
  HStack,
  Heading,
  Text,
  FormControl,
  FormErrorMessage,
  Flex,
  Textarea,
} from '@chakra-ui/react';
import { format, isBefore } from 'date-fns';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import useCreateReservation from '@/hooks/useCreateReservation';
import { Studio } from '@/hooks/useStudios';
import {
  useAuthStore,
  useStudioQueryStore,
  useReservationStore,
} from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomSubmitButton from '@/components/Forms/UsersField/CustomSubmitButton';
import PriceCalculator from '@/components/StudioSideBar/PriceCalculator';
import { studioSideBarSchema } from '@/components/StudioSideBar/helper';
import CustomLabel from '@/components/Forms/CustomLabel';
import StartTimeInput from '@/components/inputfields/StartTimeInput';
import DurationInput from '@/components/inputfields/DurationInput';
import { LocalFormData } from '@/components/searchValidation';
import {
  combineDateAndTime,
  getBrowserTimeZone,
} from '@/components/Utilities/FormatDateTime';
import { DateRangeProps } from '@/components/ReactBigCalendar/helper';
import {
  calculateEndDateTime,
  calculateStartDate,
} from '@/services/dateTimeUtility';
import CustomAccordion from '@/components/CustomAccordion';
import { flattenAttributes } from '@/components/Utilities/helper';
import { BorderedBox } from '../Utilities/CustomBorder';

interface StudioProps {
  studio: Studio;
  setDateRange: Dispatch<SetStateAction<DateRangeProps>>;
}

const StudioSideBar = ({ studio, setDateRange }: StudioProps) => {
  const { isAuthenticated, user } = useAuthStore();
  const { studioQuery, setDate, setTime, setDuration } = useStudioQueryStore();
  const { mutate } = useCreateReservation(user?.id);
  const showToast = useShowToastTop();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { setReservation } = useReservationStore();

  const initialValue = {
    date: studioQuery?.date || null,
    time: studioQuery?.time || '',
    duration: studioQuery?.duration || 0,
    query: '',
  };
  const [formValues, setFormValues] = useState<LocalFormData>(initialValue);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      startDate: initialValue?.date || null,
      startTime: initialValue?.time || '',
      duration: initialValue?.duration || 0,
      specialRequests: undefined,
    },
    resolver: zodResolver(studioSideBarSchema),
  });

  const getAccordionItems = (
    handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
  ) => [
    {
      title: 'Special Requests',
      content: <Textarea name="specialRequests" onChange={handleChange} />,
    },
  ];

  const onSubmit = (formDetails: any) => {
    if (!isAuthenticated) {
      navigate('/auth/sign_in', { state: { from: pathname + search } });
      return;
    }

    const { duration, date, time } = studioQuery;
    const formattedDateTime = combineDateAndTime(
      date ? format(new Date(date), 'yyyy-MM-dd') : '',
      time,
    );

    if (formattedDateTime && isBefore(formattedDateTime, new Date())) {
      showToast({
        message: 'Cannot select a time slot in the past',
        status: 'error',
      });
      return;
    }

    const payLoad = {
      studio_id: studio.id,
      dateTime: formattedDateTime,
      duration,
      specialRequests: formDetails?.specialRequests,
      timeZone: getBrowserTimeZone(),
    };

    mutate(payLoad, {
      onSuccess: (data: any) => {
        const details: any = flattenAttributes(data?.data);
        setReservation(details);
        navigate(
          studio.userId === user?.id
            ? '/dashboard/bookings'
            : `/studios/${details?.id}/confirmation`,
        );
        if (studio?.userId !== user?.id) {
          showToast({
            message:
              'We will hold your reservation for 5 minutes. Please complete the payment within this time.',
            status: 'loading',
          });
        }
      },
      onError: (error: any) => {
        showToast({
          message: error?.response?.data?.error,
          status: 'error',
        });
      },
    });
  };

  const handleSubmitBtnText = () => {
    if (!isAuthenticated) return 'Login to Book';
    return studio?.userId === user?.id ? 'Block Now' : 'Book Now';
  };

  useEffect(() => {
    if (formValues?.date && formValues.time && formValues?.duration) {
      setDate(formValues?.date);
      setTime(formValues?.time);
      setDuration(formValues?.duration);
      const start = calculateStartDate(formValues?.date, formValues.time);
      const end = calculateEndDateTime(start, formValues.duration);
      setDateRange({ start, end });
    }
  }, [formValues, setDateRange]);

  return (
    <BorderedBox p={4} bg="white" borderRadius="md">
      <VStack spacing={0} align="stretch" p={2}>
        <HStack alignItems="baseline" mb={2}>
          <Heading size="lg">S${studio.price ? studio.price : 0}</Heading>
          <Text fontSize="sm">/hour</Text>
        </HStack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Controller
              name="startDate"
              control={methods.control}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={!!fieldState.error} mt={2}>
                  <CustomLabel labelText="Start Date" />
                  <DatePicker
                    format="ddd, MM/DD/YYYY"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(value) => {
                      field.onChange(value.toDate() || null);
                      setFormValues((prev: any) => ({
                        ...prev,
                        date: value?.toDate(),
                      }));
                    }}
                    style={{ width: '100%' }}
                    disabledDate={(current) =>
                      current && current.isBefore(dayjs().startOf('day'))
                    }
                    allowClear={false}
                  />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <Flex gap={2} my={4}>
              <StartTimeInput
                setLocalFormData={setFormValues}
                localFormData={formValues}
                defaultValue={formValues.time}
                enableCustomStyles={false}
                height="32px"
                label="Start Time"
                width="100%"
              />
              <DurationInput
                setLocalFormData={setFormValues}
                localFormData={formValues}
                defaultValue={formValues.duration}
                enableCustomStyles={false}
                height="32px"
                width="100%"
                minDuration={studio.minimumBookingDuration}
              />
            </Flex>
            <Controller
              name="specialRequests"
              control={methods.control}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={!!fieldState.error} mt={2}>
                  <CustomAccordion items={getAccordionItems(field.onChange)} />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <PriceCalculator studio={studio} duration={formValues.duration} />
            <CustomSubmitButton width="full">
              {handleSubmitBtnText()}
            </CustomSubmitButton>
          </form>
        </FormProvider>
      </VStack>
    </BorderedBox>
  );
};

export default StudioSideBar;
