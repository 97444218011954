import {
  Box,
  Button,
  Flex,
  useToast,
  useBreakpointValue,
  Stack,
  StackDirection,
  Icon,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns-tz';
import { FaSearch } from 'react-icons/fa';
import { isBefore, setHours, setMinutes } from 'date-fns';
import { useStudioQueryStore } from '@/services/store';
import searchValidation, { LocalFormData } from '@/components/searchValidation';
import SearchInput from '@/components/inputfields/SearchInput';
import DateInput from '@/components/inputfields/DateInput';
import StartTimeInput from '@/components/inputfields/StartTimeInput';
import DurationInput from '@/components/inputfields/DurationInput';

const SearchFilter = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setQuery, setDate, setTime, setDuration, studioQuery } =
    useStudioQueryStore((state) => state);

  const [localFormData, setLocalFormData] = useState<LocalFormData>({
    query: studioQuery?.query || '',
    date: studioQuery?.date || null,
    time: studioQuery?.time || '',
    duration: studioQuery?.duration || 0,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { query, date: dateStr, time: timeStr, duration } = localFormData;

    const isValid = searchValidation({
      duration,
      time: timeStr,
      date: dateStr,
      query,
    });

    const selectedDate = dateStr ? new Date(dateStr) : null;
    const [selectedHour, selectedMinute] = timeStr
      ? timeStr.split(':').map(Number)
      : [0, 0];
    const selectedDateTime = selectedDate
      ? setHours(setMinutes(selectedDate, selectedMinute), selectedHour)
      : null;

    if (selectedDateTime && isBefore(selectedDateTime, new Date())) {
      toast({
        title: 'Cannot select a time slot in the past',
        isClosable: true,
        colorScheme: 'red',
      });
      return;
    }

    if (isValid) {
      setQuery(query || '');
      if (dateStr) setDate(dateStr);
      if (timeStr) setTime(timeStr);
      if (duration) setDuration(duration);

      const queryParams = new URLSearchParams();
      Object.entries(localFormData).forEach(([key, value]) => {
        if (value == null || value === '') return;
        queryParams.append(
          key,
          value instanceof Date
            ? format(value, 'yyyy-MM-dd')
            : value.toString(),
        );
      });
      navigate(`/studios?${queryParams.toString()}`);
    } else {
      toast({
        title: 'Date, Time, and Duration fields must be filled or none at all',
        isClosable: true,
        colorScheme: 'red',
      });
    }
  };

  const stackDirection = useBreakpointValue({
    base: 'column',
    md: 'row',
  }) as StackDirection;
  const stackAlign = stackDirection === 'row' ? 'center' : 'stretch';
  const stackSpacing = useBreakpointValue({ base: 4, md: 0 });

  const boxHeight = useBreakpointValue({ base: 'auto', md: '120px' }); // Adjust the height for mobile
  const boxWidth = useBreakpointValue({ base: 'full', lg: '900px' }); // Adjust the width for mobile
  const boxPadding = useBreakpointValue({ base: 8, md: 3, lg: 4 });

  const width = useBreakpointValue({ base: 'full', md: '180px' });
  const height = useBreakpointValue({ base: 'auto', md: '34px' });

  return (
    <Box
      margin={4}
      padding={boxPadding}
      boxShadow="lg"
      borderRadius={10}
      border="1px solid"
      borderColor="powder.600"
      display="flex"
      height={boxHeight}
      width={boxWidth}
      bg="white"
      alignItems="center"
      justifyContent="center"
    >
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Stack
          as={Flex}
          direction={stackDirection}
          spacing={stackSpacing}
          align={stackAlign}
        >
          <Flex flexGrow={1}>
            <SearchInput
              height="34px"
              setLocalFormData={setLocalFormData}
              value={localFormData?.query}
            />
          </Flex>
          <DateInput
            setLocalFormData={setLocalFormData}
            defaultValue={localFormData?.date}
            height={height}
          />
          <StartTimeInput
            height={height}
            setLocalFormData={setLocalFormData}
            localFormData={localFormData}
            defaultValue={localFormData?.time}
          />
          <DurationInput
            height={height}
            setLocalFormData={setLocalFormData}
            localFormData={localFormData}
            defaultValue={localFormData?.duration}
            hideDropDownIcon
          />
          <Button
            type="submit"
            borderRadius={5}
            minHeight="65px"
            mr={2}
            paddingX={8}
            colorScheme="honey"
            fontSize="xl"
            height={height}
            width={width}
            size="lg"
            textColor="white"
            _hover={{ bg: 'honey.600', transform: 'scale(1.05)' }}
            _active={{ transform: 'scale(0.95)' }}
          >
            <Icon as={FaSearch} mr={1} />
            Search
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default SearchFilter;
