import React from 'react';
import { Text } from '@chakra-ui/react';

const arrowStyles: Record<string, any> = {
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  width: 'auto',
  mt: '-22px',
  p: '12px',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '18px',
  transition: '0.6s ease',
  borderRadius: '0 3px 3px 0',
  userSelect: 'none',
  _hover: {
    opacity: 0.6,
    bg: 'black',
  },
};

const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <Text {...arrowStyles} right="0" onClick={onClick} zIndex={99}>
      &#10095; {/* Right arrow symbol */}
    </Text>
  );
};

export default NextArrow;
