import React from 'react';
import { Box, useBreakpointValue, Heading, Flex } from '@chakra-ui/react';
import Slider from 'react-slick';
import GameCardSkeleton from '@/components/StudioCardSkeleton';
import DiscoverCard from './DiscoverCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useRandomStudios from '@/hooks/useRandomStudios';
import './Slick.css';

const DiscoverStudios = () => {
  const { data, isLoading } = useRandomStudios(); // Fetch studios
  const studios = data?.data ?? [];
  const slidesToShow = useBreakpointValue({
    base: 1, // Mobile devices
    md: 2, // Tablets
    lg: 4, // Desktops and larger screens
  });

  const skeletonCount = useBreakpointValue({
    base: 1, // Full width on mobile
    md: 2, // Two columns on tablet
    lg: 4, // Four columns on desktop
  });

  const settings = {
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    adaptiveWidth: true,
    draggable: true,
  };

  if (studios.length < 10) {
    return null;
  }

  return (
    <Flex direction="column" mt={8} width="100%">
      <Heading size="lg" p={4} mx={2}>
        Discover Studios
      </Heading>
      <Box mx="auto" p={4} width="100%">
        {isLoading ? (
          <Flex gap={2} flexWrap="wrap" justifyContent="center">
            {Array(skeletonCount)
              .fill('')
              .map((_, index) => (
                <GameCardSkeleton key={index} width="300px" />
              ))}
          </Flex>
        ) : (
          <Slider {...settings}>
            {studios.map((studio: any) => (
              <DiscoverCard key={studio.id} studio={studio.attributes} />
            ))}
          </Slider>
        )}
      </Box>
    </Flex>
  );
};

export default DiscoverStudios;
