import React from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import HomeBanner from '@/components/Layouts/Banner/HomeBanner';
import PageFooter from '@/components/Layouts/Footer/PageFooter';
import NavbarMain from '@/components/Layouts/Navbar/NavbarMain';
import DiscoverStudios from '@/components/Layouts/Discover/DiscoverStudios';
import StudioOwnersJoinCTA from '@/components/Layouts/Discover/StudioOwnersJoinCTA';
import { CTACard } from '@/components/Layouts/Discover/CTACard';

const LandingPage = () => (
  <VStack spacing={0} minHeight="100vh">
    <NavbarMain />
    <HomeBanner />
    <Flex
      direction="column"
      width="full"
      flex="1"
      justifyContent="center"
      maxW="1300px"
      mt={10}
    >
      <Box>
        <DiscoverStudios />
        {/* <Outlet /> */}
      </Box>
      <Box>
        <CTACard />
      </Box>
      <Box>
        <StudioOwnersJoinCTA />
      </Box>
      <Box>
        <PageFooter />
      </Box>
    </Flex>
  </VStack>
);

export default LandingPage;
