import {
  Box,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Select,
  Switch,
  Text,
  Tooltip,
  IconButton,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PiClock } from 'react-icons/pi';
import { capitalize } from 'lodash';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { useTimeOptions } from '@/components/Utilities/OperatingHoursOptions';
import { timeSlotsDoNotOverlap } from '@/components/CreateStudio/StudioFormSchema';
import {
  defaultHoursDurations,
  defaultOperatingHours,
} from '@/components/CreateStudio/Constant';
import CustomLabel from '@/components/Forms/CustomLabel';
import { useStudioStore } from '@/services/store';
import { TimeSlot } from '@/components/CreateStudio/types';

interface OperatingHoursProps {
  control: any;
  setDeletedOperatingHours: (hours: any) => void;
}

const OperatingHours: React.FC<OperatingHoursProps> = ({
  control,
  setDeletedOperatingHours,
}) => {
  const {
    setValue,
    watch,
    getValues,
    formState,
    clearErrors,
    setError,
    register,
  } = useFormContext();
  const { studioDetails } = useStudioStore();
  const operatingHours = watch('operatingHours');
  const alwaysOpen = watch('alwaysOpen');
  const [dailyItems, setDailyItems] = useState<any>({});

  const handleToggleChange = (day: string) => {
    const isClosed = !operatingHours[day]?.isClosed;
    setValue(`operatingHours.${day}.isClosed`, isClosed);
    setValue(
      `operatingHours.${day}.dayOfWeek`,
      defaultOperatingHours[day].dayOfWeek,
    );

    // eslint-disable-next-line no-nested-ternary
    const timeSlots = !isClosed
      ? defaultOperatingHours[day].timeSlots.length
        ? [...defaultOperatingHours[day].timeSlots]
        : [defaultHoursDurations]
      : [];
    setValue(`operatingHours.${day}`, { ...operatingHours[day], timeSlots });

    setDailyItems((prev: any) => ({ ...prev, [day]: timeSlots }));
  };

  const handle24HoursChange = () => {
    const newValue = !alwaysOpen;
    setValue('alwaysOpen', newValue);
    if (newValue) {
      setValue('operatingHours', {});
    } else {
      setValue(
        'operatingHours',
        studioDetails?.operatingHours || defaultOperatingHours,
      );
    }
  };

  const handleOperatingHoursValidation = (day: string, timeSlots: any) => {
    const overlapResult = timeSlotsDoNotOverlap(timeSlots);
    if (overlapResult === true) {
      clearErrors(`operatingHours.${day}.timeSlots`);
    } else {
      clearErrors(`operatingHours.${day}.timeSlots`);
      overlapResult.forEach(({ index, message }) => {
        setError(`operatingHours.${day}.timeSlots.${index}`, {
          type: 'manual',
          message,
        });
      });
    }
  };

  const handleAddItem = (day: string) => {
    setDailyItems((prev: any) => {
      const updatedItems = [...(prev[day] || []), defaultHoursDurations];
      setValue(`operatingHours.${day}.timeSlots`, updatedItems);
      handleOperatingHoursValidation(day, updatedItems);
      return { ...prev, [day]: updatedItems };
    });
  };

  const handleRemoveItem = (day: any, position: number) => {
    setDailyItems((prev: any) => {
      const updatedItems =
        prev[day]?.filter((_: any, index: number) => index !== position) || [];
      const deletedItem = studioDetails?.operatingHours[day]?.timeSlots?.find(
        (item: TimeSlot) => item.id === prev[day]?.[position]?.id,
      );
      if (deletedItem) {
        setDeletedOperatingHours((prevHours: any = {}) => ({
          ...prevHours,
          [day]: {
            ...(prevHours[day] || { isClosed: false, timeSlots: [] }),
            timeSlots: [...(prevHours[day]?.timeSlots || []), deletedItem],
          },
        }));
      }
      setValue(`operatingHours.${day}.timeSlots`, updatedItems);
      handleOperatingHoursValidation(day, updatedItems);
      return { ...prev, [day]: updatedItems };
    });
  };

  const handleTimeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    day: string,
    index: number,
    type: 'openTime' | 'closeTime',
  ) => {
    const { value } = event.target;
    setValue(`operatingHours.${day}.timeSlots.${index}.${type}`, value);
    setDailyItems((prevState: any) => ({
      ...prevState,
      [day]: prevState[day].map((data: any, idx: number) =>
        idx === index ? { ...data, [type]: value } : data,
      ),
    }));

    if (type === 'openTime') {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { closeTimeOptions, startTimeOptions } = useTimeOptions(value);
      const options =
        startTimeOptions[0].props.value === value
          ? startTimeOptions
          : closeTimeOptions;
      const openTimeIndex = options.findIndex(
        (option) => option.props.value === value,
      );
      const currentCloseTime = getValues(
        `operatingHours.${day}.timeSlots.${index}.closeTime`,
      );
      const closeTimeIndex = closeTimeOptions.findIndex(
        (option) => option.props.value === currentCloseTime,
      );
      if (closeTimeIndex <= openTimeIndex) {
        const newCloseTime =
          closeTimeOptions[openTimeIndex + 1]?.props.value || '';
        setValue(
          `operatingHours.${day}.timeSlots.${index}.closeTime`,
          newCloseTime,
        );
        setDailyItems((prevState: any) => ({
          ...prevState,
          [day]: prevState[day].map((data: any, idx: number) =>
            idx === index ? { ...data, closeTime: newCloseTime } : data,
          ),
        }));
      }
    }
    handleOperatingHoursValidation(day, operatingHours?.[day]?.timeSlots);
  };

  const handleIdInput = (value: any, name: string) => (
    <Input type="hidden" value={value} {...register(name)} />
  );

  useEffect(() => {
    const initialItems = Object.keys(getValues().operatingHours).reduce(
      (acc: any, day) => {
        acc[day] = getValues().operatingHours[day].timeSlots;
        return acc;
      },
      {},
    );
    setDailyItems(initialItems);
  }, [operatingHours]);

  return (
    <Box
      bg="white"
      px={4}
      py={6}
      rounded="lg"
      border="1px"
      borderColor="powder.600"
      shadow="base"
    >
      <Heading fontSize="lg" fontWeight="medium" mb={4}>
        <Flex alignItems="flex-end" fontWeight="bold">
          <Icon as={PiClock} w={6} h={6} mr={2} />
          Operating Hours
        </Flex>
      </Heading>
      <Box>
        <HStack mb={4}>
          <Tooltip label="Enable for 24/7 operation, or customize your operating hours below.">
            <Text>Open 24 hours / 7</Text>
          </Tooltip>
          <Controller
            name="alwaysOpen"
            control={control}
            render={({ field: { value } }) => (
              <Switch
                isChecked={value}
                onChange={handle24HoursChange}
                size="lg"
              />
            )}
          />
        </HStack>
        {alwaysOpen ? (
          <Text fontSize="sm" color="garageGrey.500">
            Reminder: Your studio will be available for booking 24 hours a day,
            7 days a week.
          </Text>
        ) : (
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              xl: 'repeat(2, 1fr 3fr)',
            }}
            gap={{ lg: 10 }}
            rowGap={4}
          >
            {Object.keys(defaultOperatingHours).map((day) => (
              <React.Fragment key={day}>
                <GridItem
                  colSpan={{
                    base: 2,
                    lg:
                      Object.keys(operatingHours).length &&
                      operatingHours[day]?.isClosed &&
                      4,
                  }}
                  alignItems="left"
                  display={{ base: 'flex', lg: 'block' }}
                  justifyContent={{ base: 'space-between' }}
                  mt={{ lg: 2 }}
                >
                  <HStack>
                    <Controller
                      name={`operatingHours.${day}.isClosed`}
                      control={control}
                      render={({ field: { value } }) => (
                        <Switch
                          isChecked={!value}
                          onChange={() => handleToggleChange(day)}
                          mr={2}
                        />
                      )}
                    />
                    <CustomLabel
                      labelText={capitalize(day)}
                      fontSize="md"
                      m={0}
                      fontWeight={400}
                    />
                    {operatingHours[day]?.isClosed &&
                      operatingHours[day]?.id &&
                      handleIdInput(
                        operatingHours[day]?.id,
                        `operatingHours.${day}.id`,
                      )}
                  </HStack>
                  {!operatingHours[day]?.isClosed && (
                    <IconButton
                      isRound
                      icon={<AddIcon color="honey.500" />}
                      aria-label="Add"
                      variant="outline"
                      size="sm"
                      onClick={() => handleAddItem(day)}
                      display={{ base: 'block', lg: 'none' }}
                    />
                  )}
                </GridItem>
                {!operatingHours[day]?.isClosed && (
                  <GridItem
                    colSpan={3}
                    display="flex"
                    flexDirection="column"
                    rowGap={2}
                  >
                    {(dailyItems[day] || []).map((data: any, index: number) => {
                      const { startTimeOptions, closeTimeOptions } =
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useTimeOptions(dailyItems[day]?.[index]?.openTime);
                      const timeSlotError =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        formState?.errors?.operatingHours?.[day]?.timeSlots?.[
                          index
                        ]?.message;
                      return (
                        <Flex direction="column" key={index}>
                          <Flex align="center" gap={{ base: 2, sm: 4 }}>
                            <FormControl isInvalid={!!timeSlotError} maxW={200}>
                              <Select
                                value={data?.openTime}
                                onChange={(e) =>
                                  handleTimeChange(e, day, index, 'openTime')
                                }
                                size={{ base: 'sm', md: 'md' }}
                                sx={{ padding: '5px 10px' }}
                              >
                                {startTimeOptions}
                              </Select>
                            </FormControl>
                            <Box> to </Box>
                            <FormControl isInvalid={!!timeSlotError} maxW={200}>
                              <Select
                                value={data?.closeTime}
                                onChange={(e) =>
                                  handleTimeChange(e, day, index, 'closeTime')
                                }
                                size={{ base: 'sm', md: 'md' }}
                                sx={{ padding: '5px 10px' }}
                              >
                                {closeTimeOptions}
                              </Select>
                            </FormControl>
                            {data?.id &&
                              handleIdInput(
                                data?.id,
                                `operatingHours.${day}.timeSlots.${index}.id`,
                              )}
                            {index !== 0 ? (
                              <IconButton
                                isRound
                                width={8}
                                icon={<DeleteIcon boxSize={4} />}
                                aria-label="Remove"
                                variant="none"
                                size={{ base: 'xs', md: 'sm' }}
                                colorScheme="gray"
                                _hover={{ color: 'red.500' }}
                                onClick={() => handleRemoveItem(day, index)}
                              />
                            ) : (
                              <IconButton
                                isRound
                                width={8}
                                icon={<AddIcon color="honey.500" boxSize={4} />}
                                aria-label="Add"
                                variant="outline"
                                size={{ base: 'xs', md: 'sm' }}
                                onClick={() => handleAddItem(day)}
                                visibility={{
                                  base: 'hidden',
                                  lg: 'visible',
                                }}
                              />
                            )}
                          </Flex>
                          <Text
                            color="red.500"
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            {timeSlotError}
                          </Text>
                        </Flex>
                      );
                    })}
                  </GridItem>
                )}
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default OperatingHours;
